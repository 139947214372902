import { useState, useEffect } from "react";
import Link from "next/link";
import { Link as SmoothScroll } from "react-scroll";
import Image from "next/image";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import { useTranslation } from "next-i18next";

import NavLogo from "assets/logo.png";
import LinkButton from "common/components/buttons/linkButton";
import { useRouter } from "next/router";
import LanguageSelector from "common/components/languageSelector";

export const Header = () => {
  const [isHamburgerMenuVisible, setIsHamburgerMenuVisible] = useState(false);
  const [isNavbarTransparent, setIsNavbarTransparent] = useState(false);
  const router = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const makeTransparent = () => {
        if (window.scrollY >= 75) {
          return setIsNavbarTransparent(true);
        }
        return setIsNavbarTransparent(false);
      };
      window.addEventListener("scroll", makeTransparent);
      return () => window.removeEventListener("scroll", makeTransparent);
    }
  }, []);

  return (
    <header
      className={` min-h-[15vh] shadow-lg ${
        isNavbarTransparent
          ? "bg-white bg-opacity-95 "
          : "bg-white shadow-slate-200"
      } sticky top-0 z-20`}
    >
      {/*Desktop View */}
      <section className="mx-auto hidden max-w-screen-xl select-none items-center justify-between px-4 pt-8 lg:flex">
        <Link href="/">
          <Image
            src={NavLogo}
            alt="Submitapp Logo"
            className="max-w-[200px] cursor-pointer"
            priority
            placeholder="blur"
          />
        </Link>
        <ul className="flex items-center justify-center gap-5 text-secondary-600">
          <li
            className={`text-sm transition delay-75 hover:scale-125 hover:text-primary-500 2xl:text-base ${
              router.pathname === "/features"
                ? "underline decoration-primary-500 decoration-2 underline-offset-4"
                : ""
            }`}
          >
            <Link href="/features">{t("common.features")}</Link>
          </li>
          <li
            className={`text-sm transition delay-75 hover:scale-125 hover:text-primary-500 2xl:text-base ${
              router.pathname === "/book-a-session"
                ? "underline decoration-primary-500 decoration-2 underline-offset-4"
                : ""
            }`}
          >
            <Link href="/book-a-session">{t("common.bookASession")}</Link>
          </li>
          <li
            className={`2xl::text-base cursor-pointer text-sm transition delay-75 hover:scale-125 hover:text-primary-500 ${
              router.pathname === "/book-a-session" ? "hidden" : ""
            }`}
          >
            <SmoothScroll
              to="contact-us"
              href="#contact-us"
              spy
              smooth
              offset={-125}
              duration={400}
            >
              {t("common.contactUs")}
            </SmoothScroll>
          </li>
          <li className="flex gap-3">
            <LinkButton
              href="https://portal.submitapp.io/agency/register"
              type="primary"
              className="px-6 py-2"
              external
            >
              {t("header.register")}
            </LinkButton>
            <LinkButton
              href="https://portal.submitapp.io/agency/login"
              type="secondary"
              className="px-6 py-2"
              external
            >
              {t("header.login")}
            </LinkButton>
          </li>
          <LanguageSelector />
        </ul>
      </section>
      {/*Mobile View */}
      <section className="px-4 pt-8 lg:hidden">
        <div className="flex items-center">
          <Link href="/">
            <Image
              src={NavLogo}
              alt="Submitapp Logo"
              className="max-w-[200px] cursor-pointer"
              placeholder="blur"
            />
          </Link>
          {!isHamburgerMenuVisible ? (
            <GiHamburgerMenu
              className="my-auto ml-auto cursor-pointer text-primary-500 hover:text-primary-300"
              size={25}
              onClick={() => setIsHamburgerMenuVisible(true)}
            />
          ) : (
            <ImCross
              className="my-auto ml-auto cursor-pointer text-primary-500 hover:text-primary-300"
              size={25}
              onClick={() => setIsHamburgerMenuVisible(false)}
            />
          )}
        </div>
        {isHamburgerMenuVisible && (
          <>
            <ul className="cursor mb-3 flex flex-col  gap-3 bg-lightGrayBackground text-secondary-600 ">
              <hr className="border-t border-secondary-100" />
              <li className="px-4 text-lg hover:text-primary-500">
                <Link href="/features">{t("common.features")}</Link>
              </li>
              <hr className="border-t border-secondary-100" />
              <li className="px-4 text-lg hover:text-primary-500">
                <Link href="/book-a-session">{t("common.bookASession")}</Link>
              </li>
              <hr className="border-t border-secondary-100" />
              <li>
                <LinkButton
                  href="https://portal.submitapp.io/agency/register"
                  type="primary"
                  className="ml-3 "
                  external
                >
                  {t("header.register")}
                </LinkButton>
                <LinkButton
                  href="https://portal.submitapp.io/agency/login"
                  type="secondary"
                  className="ml-3 "
                  external
                >
                  {t("header.login")}
                </LinkButton>
              </li>
              <hr className="border-t border-secondary-100" />
            </ul>
          </>
        )}
      </section>
    </header>
  );
};
