import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";

const LanguageSelector = () => {
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (router.locale === "en") {
      setSelected("US");
    }
    if (router.locale === "fr") {
      setSelected("FR");
    }
  }, []);

  const onSelect = (code) => {
    if (code === "US") {
      setSelected("US");
      router.push({ pathname, query }, asPath, { locale: "en" });
    } else if (code === "FR") {
      setSelected("FR");
      router.push({ pathname, query }, asPath, { locale: "fr" });
    }
  };

  const showSelectedLabel = ("Show Selected Label", true);
  const showOptionLabel = ("Show Option Label", true);

  const customLabels =
    ("Custom Labels",
    {
      US: { primary: "EN" },
      FR: { primary: "FR" },
    });

  return (
    <ReactFlagsSelect
      className="mt-1 border-black"
      selected={selected}
      onSelect={onSelect}
      showSelectedLabel={showSelectedLabel}
      showOptionLabel={showOptionLabel}
      customLabels={customLabels}
      countries={["US", "FR"]}
    />
  );
};

export default LanguageSelector;
