import { useEffect, useState } from "react";
import { FiChevronUp } from "react-icons/fi";
import { useRouter } from "next/router";

import Footer from "./footer";
import { Header } from "./header";

const Layout = ({ children }) => {
  const [isScrollUpButtonVisible, setIsScrollUpButtonVisible] = useState(false);

  const router = useRouter();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const showButton = () => {
        if (window.scrollY >= 75) {
          return setIsScrollUpButtonVisible(true);
        }
        return setIsScrollUpButtonVisible(false);
      };
      window.addEventListener("scroll", showButton);
      return () => window.removeEventListener("scroll", showButton);
    }
  }, []);

  return router.pathname === "/offer-icef-vancouver-2023" ? (
    <>{children}</>
  ) : (
    <>
      <Header />
      {children}
      {isScrollUpButtonVisible && (
        <div
          className="fixed right-7 bottom-7 cursor-pointer border border-primary-500 bg-primary-500 p-[2px]  text-white"
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }
        >
          <FiChevronUp size={25} />
        </div>
      )}
      <Footer />
    </>
  );
};
export default Layout;
