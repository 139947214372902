import Link from "next/link";
import { Link as SmoothScroll } from "react-scroll";
import Image from "next/image";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import NavLogo from "assets/logo.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer aria-hidden="true" className="bg-footerBackground ">
      <section className="mx-auto flex max-w-screen-xl flex-col items-start justify-between gap-12 py-10 px-4 md:flex-row md:gap-0">
        <div className="flex flex-col items-start justify-between gap-6">
          <Link href="/">
            <Image
              src={NavLogo}
              alt="Submitapp Logo"
              height={115}
              width={384}
              className="cursor-pointer"
            />
          </Link>
          <div className="flex items-center justify-center gap-2 text-primary-500 lg:ml-14">
            <a
              href="https://www.facebook.com/submitapp.io/"
              aria-label="Facebook"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookF size={25} className="hover:text-black" />
            </a>
            <a
              href="https://www.linkedin.com/company/submitapp-io/"
              aria-label="Linkedin"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn size={25} className="hover:text-black" />
            </a>
            <a
              href="https://www.instagram.com/submitapp.io/"
              aria-label="Instagram"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram size={25} className="hover:text-black" />
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-2 self-start text-secondary-400">
          <p className="mb-2 text-xl font-semibold text-black">
            {t("footer.firstTitle")}
          </p>
          <Link href="/features">{t("common.features")}</Link>
          <Link href="/book-a-session">{t("common.bookASession")}</Link>
          <SmoothScroll
            to="contact-us"
            href="#contact-us"
            spy
            smooth
            offset={-125}
            duration={400}
            className="cursor-pointer"
          >
            {t("common.contactUs")}
          </SmoothScroll>
          <Link href="/resources">{t("common.resources")}</Link>
        </div>
        <div className="flex flex-col gap-2 self-start text-secondary-400">
          <p className="mb-2 text-xl font-semibold text-black">
            {t("footer.secondTitle")}
          </p>
          <Link href="/terms-of-use">{t("footer.termsOfUse")}</Link>
          <Link href="/privacy-policy">{t("footer.privacyPolicy")}</Link>
        </div>
      </section>
      <hr className="border-t border-secondary-100" />
      <section className="mx-auto mt-auto  max-w-screen-xl px-4 py-6">
        <p className="text-secondary-800">{t("footer.waterMark")}</p>
      </section>
    </footer>
  );
};

export default Footer;
