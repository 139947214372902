import { useEffect, useState } from "react";
import { appWithTranslation } from "next-i18next";
import GoogleAnalytics from "@bradgarropy/next-google-analytics";
import { hotjar } from "react-hotjar";
import { Montserrat } from "@next/font/google";

import Layout from "common/layout";
import "../styles/globals.css";

const montserrat = Montserrat({
  subsets: ["latin"],
  weight: "variable",
});

function MyApp({ Component, pageProps }) {
  const [initialRenderComplete, setInitialRenderComplete] = useState(false);

  useEffect(() => {
    setInitialRenderComplete(true);
  }, []);

  useEffect(() => {
    hotjar.initialize(3272473, 6);
  }, []);

  if (!initialRenderComplete) return <></>;

  return (
    <main className={montserrat.className}>
      <GoogleAnalytics
        measurementId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY}
      />
      {/*Test*/}
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </main>
  );
}

export default appWithTranslation(MyApp);
